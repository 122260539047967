import { LightHeadline } from "../../components/background/headline";
import SideContent from "../../components/sideBar/sideContent";
import ChatSection from "../../components/chat/chatSection";
import { MessageType, GroupType } from "types/messageType";
import { Participant } from "types/userTypes";
import {
  TbCircleLetterAFilled,
  TbCircleLetterBFilled,
  TbCircleLetterMFilled,
} from "react-icons/tb";
import InfoCard from "../../components/card/infoCard";
import { CaseReturnType } from "types/CaseTypes";

interface ChatViewProps {
  role: string;
  isShuttle: boolean;
  case_id: string;
  user: any;
  messages: MessageType[];
  handleSendMessage: (message: string) => void;
  onChangeGroup: (group: GroupType) => void;
  selectedGroup: GroupType;
  handleShuttle: () => void;
  participants: Map<string, Participant>;
  caseInfo?: CaseReturnType;
}

const ChatViewNew = (props: ChatViewProps) => {
  const participantIcons = {
    M: <TbCircleLetterMFilled color="white" className="w-full h-full" />,
    A: <TbCircleLetterAFilled color="white" className="w-full h-full" />,
    B: <TbCircleLetterBFilled color="white" className="w-full h-full" />,
  };

  const participantColors = {
    M: "bg-violet-600",
    A: "bg-teal-600",
    B: "bg-rose-600",
  };
  

  return (
    <div className="bg-slate-950 w-full h-[100dvh] overflow-hidden flex py-2 gap-2 px-1">
      <LightHeadline />

      <section className="h-full py-5 w-10 sm:w-20 flex shrink-0  flex-col">
        <SideContent
          selectedGroup={props.selectedGroup}
          handleChangeGroup={props.onChangeGroup}
        />
      </section>
      
      <section
        style={{ width: "calc(33% - 5rem)" }}
        className=" hidden w-0 sm:w-fit sm:grid h-full divide-y-2 divide-slate-600 divide-dotted shrink-0 rounded-md bg-slate-800  grid-rows-3  gap-2"
      >
        {props.role === 'Mediaetor' &&<header className="">
        
        </header>}
        <main className="px-2 flex flex-col relative overflow-x-hidden">
        <h3 className="text-white text-lg font-mono font-extralight text-center">
            Case Info
          </h3>
          
         
         <InfoCard caseData={props.caseInfo}/>
        </main>
        <footer className="flex flex-col w-full h-full row-span-2">
          <h3 className=" text-white text-lg font-mono font-extralight text-center">
            Participant
          </h3>
          <div className="flex-grow overflow-x-hidden border-2 divide-y-2 divide-slate-400  border-gray-900 overflow-y-scroll">
            {Array.from(props.participants).map(([key, value]) => {
              return(
              <div key={key} className="flex justify-between items-center">
                <div className="w-full h-16 bg-slate-900 flex justify-end xl:justify-center items-center relative overflow-hidden">
                  <div className={`absolute  md:top-[-1.5rem] left-[-1.5rem] w-20 h-20   md:w-24 md:h-24 ${participantColors[value.side as keyof typeof participantColors]} rounded-full`}>
                    {
                      participantIcons[
                        value.side as keyof typeof participantIcons
                      ]
                    }
                  </div>
                  <p className="text-white text-sm xl:text-3xl mr-2 font-mono">{value.displayName}</p>
                </div>
              </div>
            )})}
          </div>
        </footer>
      </section>

      <section className="flex-grow h-full bg-[#414a4f] rounded-md">
        <ChatSection
        role={props.role}
          handleShuttle={props.handleShuttle}
          isShuttle={props.isShuttle}
          handleSendMessage={props.handleSendMessage}
          selectedGroup={props.selectedGroup}
          participants={props.participants}
        />
      </section>
    </div>
  );
};
export default ChatViewNew;
