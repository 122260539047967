import CircleButton from "../../components/buttons/CircleButton";
import { BiLogOut } from "react-icons/bi";
import { IoMdChatboxes } from "react-icons/io";
import { MdOutlineSettings } from "react-icons/md";
import { PiBookFill } from "react-icons/pi";
import { GroupType } from "types/messageType";
import GroupHandler from "../chat/groupHandler";
import { IoShareOutline } from "react-icons/io5";
import NakedSmallButton from "../../components/buttons/NakedSmallButton";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "hooks/storHook";
import { getRole } from "../../utils/utilsFunction";
import PopOver from "../../components/dropdown/PopOver";
import ShareCase from "../../components/alert/shareCase";
import useMobile from "hooks/useMobile";
import CostumeDialog from "../../components/dialog/genericDialog";
import { Participant } from "types/userTypes";

interface SideContentProps {
  handleChangeGroup: (group: GroupType) => void;
  selectedGroup: GroupType;
}
//TODO: add the participants to the group handler and view

const SideContent = (props: SideContentProps) => {
  //hooks=======================
  const isMobile = useMobile();
  const navigator = useNavigate();
  const user = useAppSelector((state) => state.user);
  const role = getRole(user);

  const returnToDashboard = () => {
    if (role === "Mediator") {
      console.log("Mediator");
      navigator("/Mediator/dashboard");
    } else {
      navigator("/client-login");
    }
  };

  return (
    <div
      id="side-content"
      className="divide-y divide-dashed h-full divide-slate-300  gap-10 flex flex-col"
    >
      <header className="flex flex-col gap-5 w-full justify-center items-center">
        <CircleButton iconColor="#b3b1b1" icon={<IoMdChatboxes />} />
        <CircleButton iconColor="#b3b1b1" icon={<PiBookFill />} />
      </header>

      <center
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
        className="w-full shrink-0 h-1/2 flex-grow gap-4 "
      >
        <GroupHandler
        role={role}
          handleChangeGroup={props.handleChangeGroup}
          selectedGroup={props.selectedGroup}
        />
      </center>
      <footer className="w-full   flex-grow flex flex-col items-center justify-evenly gap-3">
        {/* share */}
        {isMobile ? (
          <CostumeDialog
            trigger={<NakedSmallButton icon={<IoShareOutline />} />}
            children={<ShareCase />}
          ></CostumeDialog>
        ) : (
          <PopOver
            className="border-2 rounded-md p-2"
            trigger={<NakedSmallButton icon={<IoShareOutline />} />}
          >
            <ShareCase />
          </PopOver>
        )}

        {/* settings */}
        <NakedSmallButton icon={<MdOutlineSettings />} onClick={() => {}} />
        {/* logout */}
        <NakedSmallButton icon={<BiLogOut />} onClick={returnToDashboard} />
      </footer>
    </div>
  );
};

export default SideContent;
