import { TabsFAQ } from "pages/mediatorPage/dashboard";
import LoginPage from "pages/login/LoginPage";
import "./App.css";
import ChatView from "pages/chat/ChatPage";
import { Route, Routes, Navigate } from "react-router-dom";
import RequireAuth from "./utils/RequireAuth";
import LoadingPage from "pages/loadingPage/loadingPage";
import AddCaseView from "pages/mediatorPage/AddCase";
import ClientLogin from "pages/login/clientLogin";
import { Toaster } from "@/components/ui/toaster";
import Logout from "pages/login/logout";

function App() {
  return (
    <>
      <Routes>
        <Route path="/loading" element={<LoadingPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/client-login" element={<ClientLogin />} />
        <Route path="/logout" element={<Logout />} />

        <Route path="/User">
          <Route path="dashboard" element={<ChatView />} />
        </Route>

        <Route path="/" element={<RequireAuth />}>
          <Route path="/Mediator">
            <Route path="dashboard" element={<TabsFAQ />} />
            <Route path="new-case" element={<AddCaseView />} />
            <Route path="chat" element={<ChatView />} />
          </Route>
        </Route>

        {/* Catch-all route for unmatched paths */}
        <Route path="*" element={<Navigate to="/login" />} />
      </Routes>
      <Toaster />
    </>
  );
}

export default App;
