import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

// Import the slice reducer ========================
import { messageReducer } from "./reducers/messagesSlice";
import { userReducer } from "./reducers/userSlice";
import { socketReducer } from "./reducers/SocketSlice";
import { caseReducer } from "./reducers/caseSlice";
import { chatReducer } from "./reducers/chatSlice";

//import API slice reducers ========================
import { userApi } from "./apis/userApi";
import { caseApi } from "./apis/caseApi";
import { clientApi } from "./apis/clientApi";
import { sessionApi } from "./apis/sessionApi";

// Import the slice actions ========================

import {
  initialMessages,
  addMessage,
  clearMessages,
} from "./reducers/messagesSlice";

import { setCase, clearCase, addParticipant } from "./reducers/caseSlice";

import { setUserProperties, clearUser } from "./reducers/userSlice";
import { setSocket } from "./reducers/SocketSlice";

import { setNotification, clearNotification } from "./reducers/chatSlice";

//middleware===========

const userPersistConfig = {
  key: "user",
  storage,
};

const persistedUserReducer = persistReducer(userPersistConfig, userReducer);

// Combine the slice reducers ========================

const rootReducer = {
  chat: chatReducer,
  case: caseReducer,
  messages: messageReducer,
  user: persistedUserReducer,
  socket: socketReducer,
  [sessionApi.reducerPath]: sessionApi.reducer,
  [userApi.reducerPath]: userApi.reducer,
  [caseApi.reducerPath]: caseApi.reducer,
  [clientApi.reducerPath]: clientApi.reducer,
};

const store = configureStore({
  reducer: rootReducer,

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false })
      .concat(userApi.middleware)
      .concat(caseApi.middleware)
      .concat(clientApi.middleware)
      .concat(sessionApi.middleware),
});

export const persistor = persistStore(store);

setupListeners(store.dispatch);

// Export the store and the actions ========================
export {
  store,
  initialMessages,
  addMessage,
  clearMessages,
  setUserProperties,
  setSocket,
  setCase,
  clearCase,
  clearUser,
  setNotification,
  clearNotification,
  addParticipant,
};

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
