import { Link } from "react-router-dom";
import { MediatorPaths } from "../../utils/utilsData";
import { ArrowButtonWithText as ArrowButton } from "../buttons/ArrowButton";
import {  useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { useAppSelector } from "hooks/storHook";
import { IoMdAdd } from "react-icons/io";
import { MdOutlineSettings } from "react-icons/md";
import { BiLogOut } from "react-icons/bi";

interface NavBarProps {
  moveBackToChat: (id: string) => void;
}

const NavBar = (props: NavBarProps) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const _case = useAppSelector((state) => state.case);

  const handleDrawerChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDrawerOpen(e.target.checked);
  };

  return (
    <>
      <div className="drawer z-30 absolute w-full flex justify-between ">
        <input
          id="my-drawer-3"
          type="checkbox"
          className="drawer-toggle"
          onChange={handleDrawerChange}
        />
        <div className="drawer-content flex flex-col">
          {/* Navbar */}
          <div className="navbar w-full absolute top-0">
            <div className="flex-none lg:hidden">
              <label
                htmlFor="my-drawer-3"
                aria-label="open sidebar"
                className="btn btn-square btn-ghost"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  className="inline-block h-6 w-6 stroke-current"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16M4 18h16"
                  ></path>
                </svg>
              </label>
            </div>
            <div className="hidden flex-none lg:block">
              <ul className="menu menu-horizontal">
                <NavBarContent />
              </ul>
            </div>
          </div>
        </div>
        <div className="drawer-side">
          <label
            htmlFor="my-drawer-3"
            aria-label="close sidebar"
            className="drawer-overlay"
          ></label>
          <ul className="menu bg-[#525c62] border-r-[0.3px] border-slate-300 border-separate min-h-full w-80 p-4">
            <NavBarContent />
          </ul>
        </div>
        <div className="pt-4 z-10  relative">
          <AnimatePresence>
            {!drawerOpen && (
              <motion.div
                initial={{ x: 0, opacity: 1 }}
                exit={{ x: 50, opacity: 0 }}
              >
                {_case.case_title && (
                  <ArrowButton
                    direction="right"
                    text={_case.case_title}
                    onClick={() => props.moveBackToChat(_case.case_id)}
                  />
                )}
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </div>
    </>
  );
};

export default NavBar;

const NavBarContent = () => {
  return (
    <>
      <li>
        <Link to={MediatorPaths.createCase}>
          <IoMdAdd className="h-4 w-4" color="black" />
          Create new case
        </Link>
      </li>
      <li>
        <Link to="settings">
          <MdOutlineSettings className="h-4 w-4" color="black" />
          Settings</Link>
      </li>
      <li>
        <Link to="/logout">
          <BiLogOut className="h-4 w-4" color="black" />
          Logout</Link>
      </li>
    </>
  );
};
