import { useAppSelector } from "hooks/storHook";
import { GroupType, MessageType } from "types/messageType";
import Message from "./Message";
import { useEffect, useRef, useState } from "react";
import { Participant } from "types/userTypes";

interface MessageBoardProps {
  selectedGroup: GroupType;
  participants: Map<string, Participant>;
}

const MessagesBoard = (props: MessageBoardProps) => {
  const user = useAppSelector((state) => state.user);
  const messages = useAppSelector((state) => state.messages);
  const endBoardRef = useRef<HTMLDivElement>(null);

  const [messageDisplay, setMessageDisplay] = useState<MessageType[]>([]);

  const isMe = (msg: MessageType) => msg.from === user.id;

  useEffect(() => {
    setMessageDisplay(
      messages.filter((msg) => msg.side === props.selectedGroup)
    );
  }, [props.selectedGroup, messages]);

  useEffect(() => {
    if (endBoardRef.current) {
      endBoardRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messageDisplay]);


  return (
    <div className="w-full h-full overflow-y-scroll snap-y snap-mandatory overflow-x-hidden py-2 ">
      {messageDisplay.map((msg) => (
        <Message
          side={msg.fromSide || 'M'}
          key={msg.time.toString()}
          msg={msg}
          isMe={isMe(msg)}
        />
      ))}
      <div ref={endBoardRef} id="end-board"></div>
    </div>
  );
};

export default MessagesBoard;
