import { AnimatePresence, motion } from "framer-motion";
import { CaseReturnType } from "types/CaseTypes";
import CaseList from "./caseList";

const CaseCollapse = ({
  selected,
  cases,
  handleStartCase,
}: {
  selected: string;
  cases: { [key: string]: CaseReturnType[] };
  handleStartCase: (id: string) => void;
}) => {
  return (
    <div className="mx-auto w-full  mt-12 max-w-3xl">
      <AnimatePresence mode="wait">
        {Object.entries(cases).map(([tab, questions]) => {
          return selected === tab ? (
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 20 }}
              transition={{
                duration: 0.5,
                ease: "backIn",
              }}
              className="space-y-4 "
              key={tab}
            >
              {questions.map((q, idx) => (
                <CaseList key={idx} {...q} handleStart={handleStartCase} />
              ))}
            </motion.div>
          ) : undefined;
        })}
      </AnimatePresence>
    </div>
  );
};
export default CaseCollapse;
