interface FormInputProps {
  placeHolder?: string;
  leftLabel?: string;
  rightLabel?: string;
  secure?: boolean;
  type?: string;
  handelChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  name: string;
  statusRef?: React.RefObject<HTMLSpanElement>;
  autoComplete?: React.HTMLInputAutoCompleteAttribute;
  autoFocus?: boolean;
  value?: string;
}

const FormInput = (props: FormInputProps) => {
  return (
    <label className="form-control w-full max-w-xs">
      <div className="label h-10">
        <span ref={props.statusRef} className="label-text">
          {props.leftLabel}
        </span>
        <span className="label-text-alt">{props.rightLabel}</span>
      </div>
      <input
        value={props.value}
        autoFocus={props.autoFocus}
        autoComplete={props.autoComplete}
        name={props.name}
        onChange={props.handelChange}
        type={props.secure ? "password" : props.type ? props.type : "text"}
        placeholder={props.placeHolder}
        className="input input-bordered w-full max-w-xs bg-[#1D232A] text-white"
      />
    </label>
  );
};
export default FormInput;
