import { useLocation, Navigate, Outlet } from "react-router-dom";

import { useAppSelector } from "hooks/storHook";
import { getRole } from "./utilsFunction";

const RequireAuth = () => {
  const user = useAppSelector((state) => state.user);
  const location = useLocation();

  const role = getRole(user);
  const { id } = user;

  const auth = location.pathname.split("/")[1] || false;

  if (role !== auth && id > 0) {
    if (!auth)
      return <Navigate to="/login" state={{ from: location }} replace={true} />;
    return (
      <Navigate to="/unauthorised" state={{ from: location }} replace={true} />
    );
  } else
    return id ? (
      <Outlet />
    ) : (
      <Navigate to="/login" state={{ from: location }} replace={true} />
    );
};
export default RequireAuth;
