import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";

import { Provider } from "react-redux";
import { store, persistor } from "./store/index";
import { PersistGate } from "redux-persist/integration/react";
import { HashRouter, Routes, Route } from "react-router-dom";
import { SocketProvider } from "hooks/useSocket";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <Provider store={store}>
    <PersistGate loading={<h1>loading...</h1>} persistor={persistor}>
      <HashRouter >
        <SocketProvider>
          <Routes >
            <Route path="/*" element={<App />} />
          </Routes>
        </SocketProvider>
      </HashRouter>
    </PersistGate>
  </Provider>
);


