import { useEffect, useState } from "react";
import { useAppSelector } from "hooks/storHook";
import { useLocation, useNavigate } from "react-router-dom";
import { CaseDataRedis, Emits } from "../../types/CaseTypes";
import { MessageType, GroupType } from "types/messageType";
import { useSocket } from "hooks/useSocket";
import { getRole } from "../../utils/utilsFunction";
import ChatViewNew from "./ChatViewNew";
import { useGetCaseByIdQuery } from "store/apis/caseApi";
import {
  addMessage,
  clearNotification,
  clearUser,
  initialMessages,
  setCase,
  setNotification,
} from "store";
import { useAppDispatch } from "hooks/storHook";
import { Participant } from "types/userTypes";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";

type caseData = {
  id: string;
  creator: string;
  shuttle: "false" | "true";
  participants: Participant[];
};

const ChatPage = () => {
  const [selectedGroup, setSelectedGroup] = useState<GroupType>("M");
  const navigate = useNavigate();

  const [participants, setParticipants] = useState<Map<string, Participant>>(
    new Map()
  );
  const caseData = useAppSelector((state) => state.case);
  const user = useAppSelector((state) => state.user);
  const messages = useAppSelector((state) => state.messages);
  const role = getRole(user);
  const { socket } = useSocket();
  const case_id = useLocation().state?.case_id || null;
  const { data: caseInfoData, error: errorCaseInfoData } =
    useGetCaseByIdQuery(case_id);
  const [isShuttle, setIsShuttle] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!socket) return;
    if (!case_id) return;

    if (role === "Mediator") {
      socket.emit(Emits.startCase, { case_id: case_id, mediator_id: user.id });
      socket.on(Emits.startCase, (res: caseData) => {
        setIsShuttle(res.shuttle === "true");
        const participantsMap = new Map<string, Participant>();
        const participantsArray = res.participants;

        participantsArray?.forEach((p) => {
          participantsMap.set(p.user_id, p);
        });
        setParticipants(() => participantsMap);
      });
    }

    socket.emit(Emits.history, { case_id: case_id });
    socket.on(Emits.roomMsg, handleRoomMessage);
    socket.on(Emits.history, handleHistoryMessages);
    socket.on(Emits.shuttle, onShuttle);
    socket.on(Emits.userJoin, handleJoin);
    socket.on(Emits.userLeave, handleLeave);

    return () => {
      socket.off(Emits.roomMsg, handleRoomMessage);
      socket.off(Emits.history, handleHistoryMessages);
      socket.off(Emits.shuttle, onShuttle);
      socket.off(Emits.userJoin, handleJoin);
      socket.off(Emits.userLeave, handleLeave);
      if (role === "Mediator") {
        socket.off(Emits.startCase);
      }
    };
  }, [socket, case_id, role, selectedGroup]); // Include `selectedGroup` here

  useEffect(() => {
    if (!caseInfoData) return;
    dispatch(
      setCase({
        category: caseInfoData.category,
        problem_brief: caseInfoData.problem_brief,
        sideA_description: caseInfoData.sideA_description,
        sideB_description: caseInfoData.sideB_description,
        sub_category: caseInfoData.sub_category,
      })
    );
  }, [caseInfoData]);

  useEffect(() => {
    if (!errorCaseInfoData) return;
    const httpError = errorCaseInfoData as FetchBaseQueryError;
    if(httpError.status === 401){
      dispatch(clearUser());
      navigate("/login");
    }

  
  }, [errorCaseInfoData]);

  useEffect(() => {
    if (role !== "User") return;
    caseData.participants.forEach((p) => {
      setParticipants((prev) => {
        const updatedMap = new Map(prev); // Create a new copy of the Map
        updatedMap.set(p.user_id, p); // Add the participant
        return updatedMap; // Return the new Map
      });
    });
    setIsShuttle(caseData.shuttle); // Set the shuttle state
  }, [caseData]);

  useEffect(() => {
    if (!case_id) return;
    const caseLink = case_id.toString().slice(-6);
    dispatch(setCase({ link: caseLink }));
  }, [case_id]);

  const handleHistoryMessages = (msgs: MessageType[]) => {
    dispatch(initialMessages(msgs));
  };

  const onShuttle = (shuttleData: CaseDataRedis) => {
    setIsShuttle(shuttleData.shuttle);
  };

  const handleRoomMessage = (msg: MessageType) => {
    if (msg.side !== selectedGroup) {
      dispatch(setNotification(msg.side)); // Notify if not the current group
    }
    dispatch(addMessage(msg)); // Add message to the store
  };

  const handleJoin = (data: Participant) => {
    setParticipants((prev) => {
      const updatedMap = new Map(prev); // Create a new copy of the Map
      updatedMap.set(data.user_id, data); // Add the participant
      return updatedMap; // Return the new Map
    });
  };

  const handleLeave = (data: Participant) => {
    setParticipants((prev) => {
      const updatedMap = new Map(prev); // Create a new copy of the Map
      updatedMap.delete(data.user_id); // Remove the participant
      return updatedMap; // Return the new Map
    });
  };

  const handleShuttle = () => {
    if (role !== "Mediator") return;
    const shuttleState = !isShuttle;
    setIsShuttle(() => shuttleState);
    socket?.emit(Emits.shuttle, {
      case_id,
      to: "M",
      mediator_id: user.id,
      shuttle: shuttleState,
    });
  };

  const handleSendMessage = (message: string) => {
    const msg: MessageType = {
      to: case_id,
      fromSide: (user.side as GroupType) || "M",
      message,
      from: user.id,
      time: new Date(),
      side: selectedGroup,
      displayName: user.first_name + " " + user.last_name,
    };
    socket?.emit(Emits.roomMsg, msg);
  };

  const handleChangeGroup = (group: GroupType) => {
    dispatch(clearNotification(group)); // Clear notification for the selected group
    setSelectedGroup(group); // This will trigger re-render and re-run the useEffect
  };

  return (
    <ChatViewNew
      caseInfo={caseInfoData}
      role={role}
      isShuttle={isShuttle}
      case_id={case_id}
      selectedGroup={selectedGroup}
      user={user}
      messages={messages}
      handleSendMessage={handleSendMessage}
      onChangeGroup={handleChangeGroup}
      handleShuttle={handleShuttle}
      participants={participants}
    />
  );
};

export default ChatPage;
