import { cloneElement } from "react";

interface CircleButtonProps {
  icon: React.ReactNode;
  iconColor?: string;
  ringColor?: string;
}

const CircleButton = (props: CircleButtonProps) => {
  return (
    <div
      id="circle-button"
      className={`w-10 h-10 rounded-full ring-1 cursor-pointer ring-offset-1 hover:ring-inset hover:ring-4 hover:ring-offset-slate-300 active:scale-95 transition-all duration-150   hover:ring-indigo-500   ${
        props.ringColor || "ring-indigo-700"
      } ring-offset-slate-500  relative `}
    >
      {cloneElement(props.icon as React.ReactElement, {
        className:
          "w-2/3 h-2/3 absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] hover:scale-150 transition-all duration-150",
        color: "white",
      })}
    </div>
  );
};

export default CircleButton;