import { GroupType, MessageType } from "types/messageType";
import {
  TbCircleLetterAFilled,
  TbCircleLetterBFilled,
  TbCircleLetterMFilled,
} from "react-icons/tb";

interface MessageProps {
  msg: MessageType;
  isMe: boolean;
  side: GroupType;
}

const Message = (props: MessageProps) => {
  const time = new Date(props.msg.time);
  const sideColor =
    props.side === "A"
      ? "from-teal-600 to-emerald-600 bg-teal-600 "
      : props.side === "B"
      ? "from-pink-600 to-rose-600 bg-pink-600"
      : "from-indigo-600 to-violet-600 bg-indigo-600";

  const participantIcons = {
    M: <TbCircleLetterMFilled color="white" className="w-full h-full" />,
    A: <TbCircleLetterAFilled color="white" className="w-full h-full" />,
    B: <TbCircleLetterBFilled color="white" className="w-full h-full" />,
  };

  return (
    <div
      className={`chat  ${props.isMe ? "chat-end" : "chat-start"} snap-start `}
    >
      <div className=" chat-image">
        <div className={` rounded-full ${sideColor} w-10 h-10 mr-0`}>
          {participantIcons[props.side]}
        </div>
      </div>
      <div className="chat-header text-slate-300">
        {props.msg.displayName || ""}
      </div>

      <div
        className={`chat-bubble min-w-14  ring-black border-white max-w-[50%] shadow-xl rounded-md  text-white`}
      >
        <p>{props.msg.message}</p>
      </div>
      <time className="chat-footer opacity-50 text-slate-300">
        {time.toLocaleTimeString()}
      </time>
    </div>
  );
};

export default Message;
