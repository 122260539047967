import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { clear } from "console";
import { GroupType } from "types/messageType";

interface Notifications {
  [key: string]: number ;
} 

interface ChatState {
  notification: Notifications;
}

const initialState: ChatState = {
  notification: {'A':0,'M':0,'B':0},
};

const chatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    setNotification: (state, action: PayloadAction<GroupType>) => {
      const groupType: GroupType = action.payload;
      const counter: number = state.notification[groupType] || 0;

      state.notification[groupType] = counter + 1;
    },
    clearNotification: (state, action: PayloadAction<GroupType>) => {
        const groupType: GroupType = action.payload;
        state.notification[groupType] = 0;
    }

  },
});

export const { setNotification, clearNotification } = chatSlice.actions;
export const chatReducer = chatSlice.reducer;
