
import CaseCollapse from "../../components/lists/caseCollapse";
import NavBar from "../../components/navBar/navBar";
import Headline from "../../components/background/headline";
import Tabs from "../../components/listTab/tabs";
import { AlertDialogView } from "../../components/alert/AlertDialog";
import { LoadingPageWithTransition as LoadingPage } from "pages/loadingPage/loadingPage";
import { motion } from "framer-motion";
import { Dispatch, SetStateAction } from "react";


interface DashboardViewProps {
    isLoading: boolean;
    casesData: any;
    dialogShow: boolean;
    selected: string;
    cases: any;
    handleDialogClose: (accept:boolean) => void;
    setSelected: Dispatch<SetStateAction<string>>;
    handleStartCase: (id: string) => void;
    moveToChat: (id: string) => void;
    tabs: string[];
}
const DashboardView = (props:DashboardViewProps) => {
 

    return(
        <>
        <LoadingPage isShown={props.isLoading} />
        {props.casesData && (
          <motion.div
            initial={props.isLoading ? { opacity: 0 } : { opacity: 1 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1, delay: 0.5 }}
          >
            <NavBar moveBackToChat={props.moveToChat} />
            <section className="w-full flex flex-col h-[100dvh] overflow-hidden bg-[#414a4f] px-4 py-12 text-slate-50">
              <Headline text="Welcome Mediator." />
              <AlertDialogView
                title="You have an active case"
                description="You have an active case, do you want to continue with it?"
                isOpen={props.dialogShow}
                onClose={props.handleDialogClose}
              />
              {props.selected && (
                <>
                  <Tabs
                    selected={props.selected}
                    setSelected={props.setSelected}
                    tabsMap={props.tabs}
                  />
                  <div className="flex w-full flex-grow overflow-scroll">
                    <CaseCollapse
                      selected={props.selected}
                      cases={props.cases}
                      handleStartCase={props.handleStartCase}
                    />
                  </div>
                </>
              )}
            </section>
          </motion.div>
        )}
      </>
    )
}
export default DashboardView;