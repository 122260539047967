import { GroupType } from "types/messageType";
import CircleIcon from "../buttons/CircleIcon";
import InputBar from "../InputBar/inputBarNew";
import MessagesBoard from "./MessageBoard";
import { Participant } from "types/userTypes";
import { ImNotification } from "react-icons/im";
import { Button } from "@/components/ui/button";
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "@radix-ui/react-hover-card";

interface ChatSectionProps {
  role: string;
  handleSendMessage: (msg: string) => void;
  selectedGroup: GroupType;
  isShuttle: boolean;
  handleShuttle: () => void;
  participants: Map<string, Participant>;
}

const ChatSection = (props: ChatSectionProps) => {
  return (
    <div className="divide-y divide-stone-600 w-full h-full ">
      <header className="w-full h-16 flex items-center px-2 justify-between   ">
        <CircleIcon />

        <div className="w-2/3 md:w-1/3 p-1">
          {props.isShuttle && (
            <NotificationBar notificationDescription="Shuttle Mode" />
          )}
        </div>
      </header>

      <section style={{ height: "calc(99% - 9rem)" }} className="w-full">
        <MessagesBoard
          participants={props.participants}
          selectedGroup={props.selectedGroup}
        />
      </section>

      <footer className="w-full h-20 flex py-2 pl-1 items-center">
        <InputBar
          role={props.role}
          handleShuttle={props.handleShuttle}
          isShuttle={props.isShuttle}
          handleSendMessage={props.handleSendMessage}
          selectedGroup={props.selectedGroup}
        />
      </footer>
    </div>
  );
};

export default ChatSection;

interface NotificationBarProps {
  notificationDescription: string;
}
const NotificationBar = (props: NotificationBarProps) => {
  return (
    <div
      role="alert"
      className="alert flex  bg-opacity-55 bg-black rounded-sm "
    >
      <HoverCard>
        <HoverCardTrigger asChild>
          <Button
            className="z-10 p-0 m-0 h-fit w-fit hover:bg-transparent bg-transparent"
            variant="ghost"
          >
            <ImNotification className="w-6 h-6 hover:scale-95 transition-all duration-200 text-sky-300 " />
          </Button>
        </HoverCardTrigger>
        <span className="text-white">{props.notificationDescription}</span>

        <HoverCardContent
          side="top"
          align="center"
          className="w-50 h-50 z-50  "
        >
          <div className="p-4  rounded-md shadow-lg bg-slate-950">
            <p className="text-white text-sm">
              Shuttle mode allows you to send messages to the mediator only.
            </p>
          </div>
        </HoverCardContent>
      </HoverCard>
    </div>
  );
};
