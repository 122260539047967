import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  ReactNode,
  useRef,
} from "react";
import { useAppDispatch } from "./storHook";
import { setCase } from "store";
import { io, Socket } from "socket.io-client";
import { useAppSelector } from "./storHook";
import { getRole } from "../utils/utilsFunction";
import { Emits } from "types/CaseTypes";
import { useLazyGetUserByIdQuery } from "store/apis/userApi";
import { Participant } from "types/userTypes";

interface SocketContextType {
  socket: Socket | null;
}

const SocketContext = createContext<SocketContextType | undefined>(undefined);

export const useSocket = (): SocketContextType => {
  const context = useContext(SocketContext);
  if (context === undefined) {
    throw new Error("useSocket must be used within a SocketProvider");
  }
  return context;
};

interface SocketProviderProps {
  children: ReactNode;
}

export const SocketProvider: React.FC<SocketProviderProps> = ({ children }) => {
  const [socket, setSocket] = useState<Socket | null>(null);
  const user = useAppSelector((state) => state.user);
  const caseData = useAppSelector((state) => state.case);
  const dispatch = useAppDispatch();
  const case_id = localStorage.getItem("case_id");
  const [getCreator] = useLazyGetUserByIdQuery();

  useEffect(() => {
    if (user.id <= 0) return;
    let newSocket: Socket;
    try {
      newSocket = io("wss://chenberti.info", {
        
        auth: { token: user.access },
        query: {
          user_id: user.id,
          role: getRole(user),
          case_id: caseData.case_id || case_id,
          side: user.side,
          displayName: user.first_name + " " + user.last_name,
        },
        reconnectionAttempts: 5,
        reconnectionDelay: 1000,
      });

      setSocket(newSocket);
    } catch (e) {
      console.log(e);
    }

    return () => {
      newSocket.close();
    };
  }, [user.id]);

  useEffect(() => {
    if (!socket) return;
    socket.on(Emits.connection, async (res) => {
      const role = getRole(user);
      if (role === "Mediator") {
        if (!res?.case_active) return;
        const { case_active } = res;

        if (!case_active) return;
        dispatch(setCase({ case_id: case_active }));
      } else if (role === "User") {
        if (!res?.creator) return;

        const { creator } = res;
        const { data } = await getCreator(creator);
        const creatorParticipant: Participant = {
          displayName: data.first_name + " " + data.last_name,
          user_id: creator,
          side: "M",
        };
        if (!res?.participants) return;
        const { participants } = res;
        const participantArray = JSON.parse(participants);
        const isShuttle = res.shuttle === "true";
        dispatch(
          setCase({
            shuttle: isShuttle,
            participants: [...participantArray, creatorParticipant],
          })
        );
      }
    });

    return () => {
      socket.off("message");
      socket.off(Emits.connection);
    };
  }, [socket]);

  return (
    <SocketContext.Provider value={{ socket }}>
      {children}
    </SocketContext.Provider>
  );
};
