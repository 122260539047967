import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { CaseReturnType } from "types/CaseTypes";
import { useState, useEffect, useRef } from "react";
import { motion } from "framer-motion";
import { ChevronDownIcon } from "lucide-react";
// import { ChevronDownIcon } from "@heroicons/react/solid"; // Example icon

interface InfoCardProps {
  caseData?: CaseReturnType;
}

export default function InfoCard({ caseData }: InfoCardProps) {
  const [showIcon, setShowIcon] = useState(true);
  const cardRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const card = cardRef.current;
    card?.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleScroll);
    return () => card?.removeEventListener("scroll", handleScroll);
  }, []);

  const handleScroll = () => {
    if (cardRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = cardRef.current;
      // Check if the user has scrolled to the bottom
      if (scrollTop + clientHeight >= scrollHeight - 10) {
        setShowIcon(false);
      } else {
        setShowIcon(true);
      }
    }
  };

  useEffect(()=>{
    handleScroll()

  },[])



  return (
    <div className="flex-grow overflow-y-scroll " ref={cardRef}>
      <Card className="bg-transparent ">
        <CardHeader className="p-4 pb-0 ">
          <CardTitle className="text-white h-8 font-mono ">
            <p className="text-nowrap text-lg lg:text-2xl  ">
              {caseData?.title}
            </p>
          </CardTitle>
          <CardDescription>{caseData?.problem_brief}</CardDescription>
        </CardHeader>
        <CardContent className="flex flex-col mt-2 flex-grow items-baseline gap-4 p-4 pt-0">
          <ul className="text-white ">
            <li>
              Category:{" "}
              <span className="text-slate-400 text-sm">
                {caseData?.category}
              </span>
            </li>
            <li>
              Sub Category:{" "}
              <span className="text-slate-400 text-sm">
                {caseData?.sub_category}
              </span>
            </li>
            <li>
              Mediator:{" "}
              <span className="text-slate-400 text-sm">
                {caseData?.mediator}
              </span>
            </li>
          </ul>
          <div className="items-baseline gap-1  text-2xl text-nowrap font-bold tabular-nums text-teal-600 leading-none">
            SIDE A:{" "}
            <span className="text-sm break-words text-wrap font-normal text-muted-foreground">
              {caseData?.sideA_description}
            </span>
          </div>
          <div className="items-baseline gap-1 text-2xl text-rose-600 font-bold tabular-nums leading-none">
            SIDE B:{" "}
            <span className="text-sm break-words text-wrap font-normal text-muted-foreground">
              {caseData?.sideB_description}
            </span>
          </div>
        </CardContent>
        {showIcon && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="absolute bottom-0 left-1/2 transform -translate-x-1/2"
          >
            <ChevronDownIcon className="h-6 w-6 text-white" />
          </motion.div>
        )}
      </Card>
    </div>
  );
}
