import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { PostCaseType, CaseReturnType } from "types/CaseTypes";

type loginClientReturnedType = {
  case_id: string;
};

export const caseApi = createApi({
  reducerPath: "caseApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `/api`,
    prepareHeaders: (headers, { getState }: { getState: () => any }) => {
      const access = getState().user.access;
      if (access) headers.set("Authorization", `JWT ${access}`);
      return headers;
    },
  }),
  tagTypes: ["newCase"],
  endpoints(builder) {
    return {
      createCase: builder.mutation({
        invalidatesTags: ["newCase"],
        query: (data: PostCaseType) => {
          console.log(data);
          return {
            url: "/session/case/",
            method: "POST",
            body: data,
          };
        },
      }),
      getMyCases: builder.query<CaseReturnType[], number>({
        providesTags: ["newCase"],
        query: (userId: number) => {
          return {
            url: "/session/case/get_mediator_cases/",
            method: "GET",
            params: {
              userId,
            },
          };
        },
      }),
      loginUser: builder.query<any, string>({
        query: (link_id: string) => {
          return {
            url: `/session/get_case_full_id/`,
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
            params: {
              link_id,
            },
          };
        },
      }),
      setCaseLink: builder.mutation<
        string,
        { case_id: string; link_id: string }
      >({
        query: ({ case_id, link_id }) => {
          return {
            url: `/session/set_case_full_id/`,
            method: "POST",
            body: { case_id, link_id },
          };
        },
      }),
      deleteCaseLink: builder.mutation<string, { link_id: string }>({
        query: ({ link_id }) => {
          return {
            url: `/session/delete_case_full_id/`,
            method: "DELETE",
            body: { link_id },
          };
        },
      }),
      getCaseById: builder.query<CaseReturnType, string>({
        query: (case_id: string) => {
          return {
            url: `/session/case/${case_id}/`,
            method: "GET",
          };
        },
      }),
    };
  },
});

export const {
  useCreateCaseMutation,
  useGetCaseByIdQuery,
  useGetMyCasesQuery,
  useLazyGetMyCasesQuery,
  useLazyLoginUserQuery,
  useSetCaseLinkMutation,
  useDeleteCaseLinkMutation,
  useLazyGetCaseByIdQuery,
} = caseApi;
