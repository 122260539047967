import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { MessageType } from "types/messageType";

const initialState: MessageType[] = [];

const MessageSlice = createSlice({
  name: "messages",
  initialState,
  reducers: {
    initialMessages: (state, action: PayloadAction<MessageType[]>) => {
      return action.payload;
    },
    addMessage: (state, action: PayloadAction<MessageType>) => {
      state.push(action.payload);
    },
    clearMessages: (state) => {
      return [];
    },
  },
});

export const { initialMessages, addMessage, clearMessages } =
  MessageSlice.actions;
export const messageReducer = MessageSlice.reducer;
