import { motion } from "framer-motion";
import { useState } from "react";
import { FiPlus } from "react-icons/fi";
import useMeasure from "react-use-measure";
import { CaseReturnType, Emits } from "types/CaseTypes";

interface CaseListProps extends CaseReturnType {
  handleStart: (id: string) => void;
}

const CaseList = (props: CaseListProps) => {
  const [ref, { height }] = useMeasure();
  const [open, setOpen] = useState(false);

  return (
    <motion.div
      animate={open ? "open" : "closed"}
      className={`rounded-xl  border-[1px] border-slate-700 px-4 transition-colors ${
        open ? "bg-slate-800" : "bg-slate-900"
      }`}
    >
      <button
        onClick={() => setOpen((pv) => !pv)}
        className="flex w-full items-center justify-between gap-4 py-4"
      >
        <span
          className={`text-left text-lg font-medium transition-colors ${
            open ? "text-slate-50" : "text-slate-400"
          }`}
        >
          {props.title}
        </span>
        <motion.span
          variants={{
            open: {
              rotate: "45deg",
            },
            closed: {
              rotate: "0deg",
            },
          }}
        >
          <FiPlus
            className={`text-2xl transition-colors ${
              open ? "text-slate-50" : "text-slate-400"
            }`}
          />
        </motion.span>
      </button>
      <motion.div
        initial={false}
        animate={{
          height: open ? height : "0px",
          marginBottom: open ? "24px" : "0px",
        }}
        className="overflow-hidden text-slate-400"
      >
        <div ref={ref}>
          <p className="badge mb-2">{props.category}</p>
          <p className="">{props.problem_brief}</p>
          {props.is_active && (
            <div className="mt-5  flex gap-4">
              <button
                onClick={() => props.handleStart(props.id || '-1')}
                className="btn text-white btn-sm bg-emerald-300 border-none rounded-none"
              >
                Start
              </button>
              <button className="btn text-white btn-sm bg-sky-400 border-none rounded-none">
                Info
              </button>
              <button className="btn text-white btn-sm bg-rose-400 border-none rounded-none">
                Close case
              </button>
            </div>
          )}
        </div>
      </motion.div>
    </motion.div>
  );
};

export default CaseList;
