import { FiArrowRight } from "react-icons/fi";

interface PopButtonProps {
  text: string;
  handleClick?: () => void;
  loading?: boolean;
}

const PopButton = (props: PopButtonProps) => {
  return (
    <button
      onClick={props.handleClick}
      type="submit"
      className="group w-full max-w-40 flex shrink-0 items-center gap-1.5 bg-emerald-300 px-4 py-3 text-sm font-medium text-neutral-900 transition-transform active:scale-[0.985]"
    >
      {props.loading ? (
        <>
          <p>Loading</p>
          <span className="loading loading-ring loading-xs"></span>
        </>
      ) : (
        <>
          <span>{props.text}</span>
          <FiArrowRight className="-mr-4 opacity-0 transition-all group-hover:-mr-0 group-hover:opacity-100" />
        </>
      )}
    </button>
  );
};
export default PopButton;
