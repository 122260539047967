import { MdBusinessCenter } from "react-icons/md";

const CircleIcon = () => {
    return (
      <div className="w-12 h-12 relative rounded-full bg-gradient-to-br from-rose-300 to-rose-400 ring-1 ring-white shadow-md shadow-rose-300">
        <MdBusinessCenter
          className="w-6 h-6 absolute  left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%]"
          color="white"
        />
      </div>
    );
  };


export default CircleIcon;